<template>
  <div v-if="!loading">
    <div v-for="(dayData, index) in actionsGrouped" :key="index">
      <Timeline
        :value="dayData.actions"
        align="alternate"
        class="customized-timeline"
      >
        <template #marker="slotProps">
          <!-- v-if="slotProps.item.icon" -->
          <div
            v-if="slotProps.index == 0"
            style="border-radius: 15px"
            class="w-7rem md:w-10rem h-2rem bg-blue-500 text-white flex justify-content-center align-items-center"
          >
            <!-- <span>
            {{ $dayjs(slotProps.item.date).format("DD MMM YYYY") }}
          </span> -->
            <span>{{
              $dayjs(dayData.date, "DD/MM/YYYY").isSame($dayjs(), "date")
                ? $t("profile.today")
                : $dayjs(dayData.date, "DD/MM/YYYY").format("DD MMM YYYY")
            }}</span>
          </div>
          <div v-else-if="Object.keys(slotProps.item).length > 0">
            <div class="w-7rem md:w-10rem flex justify-content-center">
              <div
                class="border-circle w-3rem h-3rem border-solid border-200"
              ></div>
            </div>
          </div>
          <div v-else></div>
        </template>
        <!-- <template #connector>
        <div class="p-timeline-event-connector"></div>
      </template> -->
        <template #content="slotProps">
          <Card v-if="cases">
            <template #subtitle>
              <div class="flex justify-content-between">
                <div>{Client} created application</div>
                <div>08:30 AM</div>
              </div>
            </template>
            <template #content>
              <div class="flex justify-content-between">
                <div>Application ID: 543453434</div>
                <div><Button class="p-button-text mdi mdi-24px mdi-eye" /></div>
              </div>
            </template>
          </Card>
          <Card
            v-else-if="slotProps.item.message"
            :class="slotProps.index == dayData.actions.length - 2 ? 'mb-4' : ''"
          >
            <template #subtitle>
              <!-- <div class="flex justify-content-between">
              <div>
                {{ slotProps.item.status }}
              </div>
              <div>02 {{ $t("profile.hoursAgo") }}</div>
            </div> -->
              <div class="text-left">
                <span>
                  {{
                    $dayjs(slotProps.item.actiondatetime, "DD/MM/YYYY HH:mm:ss").format(
                      "dddd DD MMM YYYY"
                    )
                  }}
                </span>
              </div>
            </template>
            <template #content>
              <div class="p-fluid">
                <!-- <InputText
                :placeholder="`${$t('profile.writeComment')}...`"
                class="field"
              >
              </InputText> -->

                <div class="flex justify-content-between">
                  <div class="text-left">
                    {{ slotProps.item.message }}
                  </div>
                  <div class="text-center">{{ timeValue(slotProps.item) }}</div>
                </div>
              </div>
            </template>
          </Card>
        </template>
      </Timeline>
    </div>
  </div>
  <div v-else>
    <Timeline
      :value="loadingData"
      align="alternate"
      class="customized-timeline"
    >
      <template #marker="slotProps">
        <div
          v-if="slotProps.item.icon"
          style="border-radius: 15px"
          class="w-7rem md:w-10rem h-2rem bg-blue-500 text-white flex justify-content-center align-items-center"
        >
          <span>
            <!-- {{ $dayjs(slotProps.item.date).format("DD MMM YYYY") }} -->
          </span>
        </div>
        <div v-else>
          <div class="w-7rem md:w-10rem flex justify-content-center">
            <div
              class="border-circle w-3rem h-3rem border-solid border-200"
            ></div>
          </div>
        </div>
      </template>
      <template #content="slotProps">
        <Card>
          <template #subtitle>
            <div class="flex justify-content-between">
              <Skeleton width="20%" height="1.2rem"></Skeleton>
              <div>
                {{ slotProps.item.status }}
              </div>
              <!-- <div>02 {{ $t("profile.hoursAgo") }}</div> -->
            </div>
          </template>
          <template #content>
            <div class="p-fluid">
              <Skeleton width="100%" height="3rem" class="mb-2"></Skeleton>
            </div>
          </template>
        </Card>
      </template>
    </Timeline>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Timeline view",
  props: ["cases", "loading"],
  data() {
    return {
      loadingData: [{}, {}, {}, {}, {}],
    };
  },
  computed: {
    ...mapGetters(["actionsGrouped"]),
  },
  methods: {
    timeValue(value) {
      let dateObject = this.$dayjs(value.actiondatetime, "DD/MM/YYYY HH:mm:ss");
      let diff = this.$dayjs().diff(
        this.$dayjs(dateObject.format()),
        "hour",
        true
      );

      if (diff < 1) {
        return this.$dayjs(dateObject.format()).from(this.$dayjs().add(1,'minute').format());
      } else {
        return dateObject.format("HH:mm");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  ::v-deep(.customized-timeline) {
    .p-timeline-event:nth-child(even) {
      flex-direction: row !important;

      .p-timeline-event-content {
        text-align: left !important;
      }
    }

    .p-timeline-event-opposite {
      flex: 0;
    }

    .p-card {
      margin-top: 1rem;
    }
  }
}
</style>
