<template>
  <Dialog
    :header="$t('cases.historyOfAudit')"
    v-model:visible="viewHistory"
    :draggable="false"
    :modal="true"
    :style="{ width: '80vw' }"
  >
  <TimelineView :cases="true"></TimelineView>
    <template #footer>
      <Button
        :label="$t('global.cancel')"
        @click="close"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import TimelineView from "../Timeline.vue";
export default {
  name: "Case history",
  props: ["display"],
  emits: ["closeCaseHistory"],
  components: {
    TimelineView
  },
  data() {
    return {
      viewHistory: false,
    };
  },
  watch: {
    async display(val) {
      this.viewHistory = val;
    },
    async viewHistory(val) {
      if (val == false) {
        this.close();
      }
    },
  },
  methods: {
    close(success = false) {
      this.$emit("closeCaseHistory", success);
    },
  },
};
</script>
