<template>

  <Dialog
    :header="$t('renewals.contactWithClient') + ' #' + data.id"
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '60vw' }"
  >
     <AddEditAction
    :display="viewAddAction"
    :renewalValue="data"
    :index="indexAction"
    @closeAddAction="closeAddAction"
  ></AddEditAction>
    <div class="grid flex-column grid-nogutter">
      <div class="col">
        <DateAndCompanyInfo :data="data"></DateAndCompanyInfo>
      </div>
    </div>

    <p class="text-xl m-0">{{ $t("renewals.actions", 2) }}</p>
    <p class="text-400">{{ $t("renewals.enterAction") }}</p>
    <DataTable
      :value="data.actions"
      :paginator="true"
      :rows="10"
      ref="tableActions"
      dataKey="id"
      showGridlines
      class="p-datatable-renewals"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      :currentPageReportTemplate="$tableString"
      removableSort
      breakpoint="1366px"
      sortField="dateAction"
      :sortOrder="-1"
    >
      <Column field="dateAction" :header="$t('renewals.date')">
        <template #body="{ data }">
          {{ data.dateAction }}
        </template>
      </Column>

      <Column field="time" :header="$t('renewals.time')">
        <template #body="{ data }">
          {{ data.time }}
        </template>
      </Column>

      <Column field="action" :header="$t('renewals.actions', 2)">
        <template #body="{ data }">
          {{ data.action }}
        </template>
      </Column>

      <Column field="companyRepresentative" :header="$t('renewals.companyRep')">
        <template #body="{ data }">
          <span class="text-right">{{ data.companyRepresentative }}</span>
        </template>
      </Column>
      <Column field="status" :header="$t('renewals.status')">
        <template #body="{ data }">
          {{ data.status }}
        </template>
      </Column>
      <Column field="comments" :header="$t('renewals.comments')">
        <template #body="{ data }">
          {{ data.comments }}
        </template>
      </Column>
      <Column field="actions" :header="$t('global.actions')" v-if="checkRole(['sales'])">
        <template #body="{ index }">
          <div class="flex justify-content-evenly">
            <Button
              class="p-button-text mdi mdi-table-edit mdi-24px"
              @click="editAction(index)"
            />
            <Button
              class="p-button-text mdi mdi-close-circle mdi-24px"
              @click="deleteAction(index)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <div class="grid pt-3">
      <div class="col-12 md:col-3 md:col-offset-9 text-right">
        <Button
          @click="addAction"
          :label="$t('renewals.addContractSection')"
          class="p-button-warning"
          v-if="checkRole(['sales'])"
        >
        </Button>
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('global.save')"
        @click="save()"
        class="p-button-success"
        iconPos="right"
        :loading="loading"
        v-if="checkRole(['sales'])"
      />
      <Button
        :label="$t('global.close')"
        @click="close(false)"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import DateAndCompanyInfo from "../Cases/DateAndCompanyInfo.vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/utilities/i18n-validators";
import AddEditAction from "./AddEditAction.vue";
import { mapGetters } from "vuex";

export default {
  name: "Contact client",
  props: ["display", "renewalValue"],
  emits: ["closeContactClient"],
  components: {
    DateAndCompanyInfo,
    AddEditAction,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      loading: false,
      actionOptions: ["Email", "Phone call"],
      statusOptions: ["Accepted", "Pending", "Declined"],
      action: {
        dateAction: "",
        time: "",
        action: "",
        companyRepresentative: "",
        status: "",
        comments: "",
      },
      disableSave: true,
      viewAddAction: false,
      addActionRenewal: {},
      indexAction: null,
    };
  },
  validations: {
    action: {
      dateAction: {
        required,
      },
      time: {
        required,
      },
      action: {
        required,
      },
      companyRepresentative: {
        required,
      },
      status: {
        required,
      },
      comments: {
        required,
      },
    },
  },
  watch: {
    display(val) {
      this.viewDialog = val;
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      }
    },
    action: {
      async handler() {
        const result = await this.v$.$validate();
        console.log(result);
        if (result) {
          this.disableSave = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    data() {
      return {
        ...this.renewalValue,
      };
    },
    ...mapGetters(['checkRole'])
  },
  methods: {
    async save() {
      this.loading = true;
      await this.$store.dispatch("updateRenewal", this.data).then(() => {
        this.$action("add-renewal-action");
      })
      this.loading = false;
      this.$notification(
        this.$t("renewals.updateRenewal"),
        this.$t("renewals.saveRenewal")
      );
      this.close(true);
    },
    closeAddAction() {
      this.viewAddAction = false;
      this.addActionRenewal = {};
      this.indexAction = null;
    },
    close(success = false) {
      this.action = {
        dateAction: "",
        time: "",
        action: "",
        companyRepresentative: "",
        status: "",
        comments: "",
      };
      this.$emit("closeContactClient", success);
    },
    addAction() {
      //       this.data.actions.push(this.action);
      //       this.action.dateAction = this.$dayjs(this.action.dateAction).format(
      //         "YYYY-MM-DD"
      //       );
      //       this.action.time = this.$dayjs(this.action.time).format("HH:mm");
      //       this.action = {
      //         dateAction: "",
      //         time: "",
      //         actions: "",
      //         companyRepresentative: "",
      //         status: "",
      //         comments: "",
      //       };
      this.viewAddAction = true;
      console.log(this.renewalValue);
      this.addActionRenewal = { ...this.renewalValue };
    },
    editAction(index) {
      this.indexAction = index;
      this.viewAddAction = true;
    },
    async deleteAction(index) {
      this.data.actions.splice(index, 1);
      await this.$store.dispatch("updateRenewal", this.data);
    },
  },
};
</script>
