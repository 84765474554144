<template>
  <Dialog
    :header="$t('renewals.contactWithClient') + ' #' + data.id"
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '30vw' }"
    style="max-height: 100% !important"
    appendTo="self"
  >
    <div class="grid align-items-center">
      <div class="col-12 md:col-3 pb-0 md:pb-3">
        <label for="date">{{ $t("renewals.date") }}*:</label>
      </div>
      <div class="field col-12 p-fluid mb-0 md:col-9 pb-3 pt-0 md:pt-3">
        <Calendar
          id="date"
          :placeholder="$t('renewals.date')"
          v-model="v$.action.dateAction.$model"
          dateFormat="dd/mm/yy"
          :showIcon="true"
          :class="{
            'p-invalid': v$.action.dateAction.$invalid && submitted,
          }"
        />
      </div>
      <ul
        v-if="v$.action.dateAction.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-5 mt-0"
      >
        <li v-for="(error, index) of v$.action.dateAction.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("renewals.date")) }}
        </li>
      </ul>
    </div>
    <div class="grid align-items-center">
      <div class="col-12 md:col-3 pb-0 md:pb-3">
        <label for="time">{{ $t("renewals.time") }}*:</label>
      </div>
      <div
        tabindex="2"
        class="field col-12 p-fluid mb-0 md:col-9 pb-3 pt-0 md:pt-3"
      >
        <Calendar
          id="time"
          v-model="v$.action.time.$model"
          :timeOnly="true"
          hourFormat="24"
          autocomplete="off"
          :placeholder="$t('renewals.time')"
          :class="{
            'p-invalid': v$.action.time.$invalid && submitted,
          }"
        />
      </div>
      <ul
        v-if="v$.action.time.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-5 mt-0"
      >
        <li v-for="(error, index) of v$.action.time.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("renewals.time")) }}
        </li>
      </ul>
    </div>

    <div class="grid vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0 md:pb-3">
        <label for="action">{{ $t("renewals.actions", 1) }}*:</label>
      </div>
      <div
        tabindex="3"
        class="field col-12 p-fluid mb-0 md:col-9 pb-3 pt-0 md:pt-3"
      >
        <Dropdown
          id="action"
          v-model="v$.action.action.$model"
          :options="renewalDropdown.action"
          :placeholder="$t('renewals.actions', 1)"
          :class="{
            'p-invalid': v$.action.action.$invalid && submitted,
          }"
          :loading="loadingDropdown"
        />
      </div>
      <ul
        v-if="v$.action.action.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-5 mt-0"
      >
        <li v-for="(error, index) of v$.action.action.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("renewals.actions", 1)) }}
        </li>
      </ul>
    </div>

    <div class="grid vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0 md:pb-3">
        <label for="companyRep">{{ $t("renewals.companyRep") }}*:</label>
      </div>
      <div
        tabindex="4"
        class="field col-12 p-fluid mb-0 md:col-9 pb-3 pt-0 md:pt-3"
      >
        <InputText
          id="companyRep"
          type="text"
          :placeholder="$t('renewals.companyRep')"
          v-model="v$.action.companyRepresentative.$model"
          :class="{
            'p-invalid': v$.action.companyRepresentative.$invalid && submitted,
          }"
        />
      </div>
      <ul
        v-if="v$.action.companyRepresentative.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-5 mt-0"
      >
        <li
          v-for="(error, index) of v$.action.companyRepresentative.$errors"
          :key="index"
        >
          {{ error.$message.replace("[value]", $t("renewals.companyRep")) }}
        </li>
      </ul>
    </div>

    <div class="grid vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0 md:pb-3">
        <label for="status">{{ $t("renewals.status") }}*:</label>
      </div>
      <div
        tabindex="5"
        class="field col-12 p-fluid mb-0 md:col-9 pb-3 pt-0 md:pt-3"
      >
        <Dropdown
          id="status"
          :placeholder="$t('renewals.status')"
          v-model="v$.action.status.$model"
          :options="renewalDropdown.status"
          :class="{
            'p-invalid': v$.action.status.$invalid && submitted,
          }"
          :loading="loadingDropdown"
        />
      </div>
      <ul
        v-if="v$.action.status.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-5 mt-0"
      >
        <li v-for="(error, index) of v$.action.status.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("renewals.status")) }}
        </li>
      </ul>
    </div>

    <div class="grid vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0 md:pb-3">
        <label for="comments">{{ $t("renewals.comments") }}:</label>
      </div>
      <div
        tabindex="6"
        class="field col-12 p-fluid mb-0 md:col-9 pb-3 pt-0 md:pt-3"
      >
        <InputText
          id="comments"
          :placeholder="$t('renewals.comments')"
          type="text"
          v-model="action.comments"
        />
      </div>
    </div>
    <div class="grid">
      <div class="col">
        <span>* {{ $t("global.mandatoryFields") }}</span>
      </div>
    </div>

    <template #footer>
      <Button
        :label="this.index != null ? $t('global.save') : $t('renewals.add')"
        @click="addAction"
        class="p-button-success"
        iconPos="right"
        :loading="loading"
      />
      <Button
        :label="$t('global.close')"
        @click="close(false)"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/utilities/i18n-validators";
import { mapGetters } from "vuex";

export default {
  name: "Add action",
  props: ["display", "renewalValue", "index"],
  emits: ["closeAddAction"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      loading: false,
      action: {
        dateAction: "",
        time: "",
        action: "",
        companyRepresentative: "",
        status: "",
        comments: "",
      },
      submitted: false,
      loadingDropdown: false,
    };
  },
  validations: {
    action: {
      dateAction: {
        required,
      },
      time: {
        required,
      },
      action: {
        required,
      },
      companyRepresentative: {
        required,
      },
      status: {
        required,
      },
    },
  },
  watch: {
    display(val) {
      this.viewDialog = val;
      console.log(this.renewalValue);
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      } else {
        console.log(this.action);
        console.log(this.index);
        if (this.index != null) {
          this.action = { ...this.data.actions[this.index] };
          this.action.time = this.$dayjs(this.action.time, "HH:mm").toDate();
          this.action.dateAction = this.$dayjs(
            this.action.dateAction,
            "DD/MM/YYYY"
          ).toDate();
        }

        this.loadingDropdown = true;
        await Promise.all([
          this.$store.dispatch("renewalsDropDown", "action"),
          this.$store.dispatch("renewalsDropDown", "status"),
        ]);
        this.loadingDropdown = false;
      }
    },
  },
  computed: {
    data() {
      return {
        ...this.renewalValue,
      };
    },
    ...mapGetters(["renewalDropdown"]),
  },
  methods: {
    close(success = false) {
      this.action = {
        dateAction: "",
        time: "",
        action: "",
        companyRepresentative: "",
        status: "",
        comments: "",
      };
      this.submitted = false;
      this.$emit("closeAddAction", success);
    },
    async addAction() {
      this.submitted = true;
      const result = await this.v$.$validate();
      if (result) {
        this.loading = true;
        this.action.dateAction = this.$dayjs(
          this.action.dateAction
        ).format("DD/MM/YYYY");
        this.action.time = this.$dayjs(this.action.time).format("HH:mm");
        if (this.index != null) {
          this.data.actions[this.index] = { ...this.action };
        } else {
          this.data.actions.push(this.action);
        }
        await this.$store.dispatch("updateRenewal", this.data).then(() => {
          this.$action("renewal-action-add");
        });
        this.loading = false;
        this.$notification(
          this.$t("renewals.updateRenewal"),
          this.$t("renewals.saveRenewal")
        );
        this.close(true);
      }
    },
  },
};
</script>
