<template>
  <ContactClient
    :display="showAddAction"
    :renewalValue="selectedRenewal"
    @closeContactClient="closeContactClient"
  >
  </ContactClient>

  <CaseHistory :display="showHistory" @closeCaseHistory="closeHistory">
  </CaseHistory>

  <Card>
    <template #content  v-if="loading || renewals.length > 0">
      <div class="grid">
        <div class="col-4">
          <h4>{{ $t("renewals.title") }}</h4>
        </div>
        <div class="col-8 md:col-2 col-offset-0 md:col-offset-6 text-right">
          <Button v-if="!isMobile" :label="$t('global.export')" @click="exportCSV"></Button>
        </div>
      </div>
      <DataTable
        :value="renewals"
        :paginator="true"
        :rows="10"
        :loading="loading"
        :filters="filters"
        ref="tableFiles"
        dataKey="id"
        showGridlines
        class="p-datatable-files"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        :currentPageReportTemplate="$tableString"
        removableSort
        breakpoint="1548px"
        sortField="certificationDate"
        :sortOrder="-1"
      >
        <template #header>
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('global.search')"
              />
            </span>
          </div>
        </template>
        <template #empty> {{ $t("renewals.noRenewalsFound") }} </template>
        <Column
          field="id"
          :header="$t('renewals.id')"
          :sortable="true"
        >
          <template #body="{ data }">
            <div class="text-right md:text-left">
              {{ data.id }}
            </div>
          </template>
        </Column>
        <Column
          field="tempCertificationDate"
          :header="$t('renewals.certificationDate')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ $dayjs(data.certificationDate,'DD/MM/YYYY').format('DD/MM/YYYY') }}
          </template>
        </Column>
        <Column
          field="companyName"
          :header="$t('renewals.company')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.companyName }}
          </template>
        </Column>
        <Column
          field="companyRepresentative"
          :header="$t('renewals.companyRep')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.companyRepresentative }}
          </template>
        </Column>
        <Column field="status" :header="$t('renewals.status')" :sortable="true">
          <template #body="{ data }">
            {{ translatedStatus(data.status) }}
          </template>
        </Column>
        <Column field="certificationId" :header="$t('renewals.certificationCode')" :sortable="true">
        </Column>
        <Column
          field="tempCertificationExpirationDate"
          :header="$t('renewals.expirationDate')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.certificationExpirationDate }}
          </template>
        </Column>
        <Column
          field="tempLastContactDate"
          :header="$t('renewals.lastContactDate')"
          :sortable="true"
        >
          <template #body="{ data }">
             {{
              data.lastContactDate
                ? data.lastContactDate
                : $t("global.noDate")
            }}
          </template>
        </Column>
        <Column
          field="comments"
          :header="$t('renewals.comments')"
          :sortable="true"
        >
          <template #body="{ data, index }">
            <CommentsField :dataValue="data"
              :indexValue="index"
              :type="'renewal'"></CommentsField>
          </template>
        </Column>
        <Column
          field="Actions"
          :header="$t('global.actions')"
          :exportable="false"
        >
          <template #body="{ data }">
            <div class="flex justify-content-evenly">
              <router-link
                :to="`/clientInformation?userId=${data.userId}`"
                class="flex justify-content-center align-items-center"
              >
                <span class="mdi mdi-24px mdi-account-box"></span>
              </router-link>
              <!-- <Button
                class="p-button-text mdi mdi-24px mdi-folder-outline"
                @click="viewHistory"
              /> -->
              <Button
                class="p-button-success"
                :label="$t('global.view')"
                @click="viewAddAction(data)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
    <template #content v-else-if="!loading">
      <NoTableData :type="'renewals'"></NoTableData>
    </template>
  </Card>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { mapGetters } from "vuex";
import ContactClient from "./ContactClient.vue";
import CaseHistory from "../Cases/CaseHistory.vue";
import NoTableData from "../NoTableData";
import CommentsField from "../CommentsField.vue";
export default {
  name: "Renewals",
  components: {
    ContactClient,
    CaseHistory,
    NoTableData,
    CommentsField
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      loading: false,
      showAddAction: false,
      selectedRenewal: {},
      showHistory: false,
    };
  },
  computed: {
    ...mapGetters(["renewals", "translatedStatus","isMobile"]),
  },
  mounted() {
    this.getRenewals();
    this.$action("renewals-page-view");
  },
  methods: {
    exportCSV() {
      this.$refs.tableFiles.exportCSV();
      this.$action("export-renewals");
    },
    async getRenewals() {
      this.loading = true;
      await this.$store.dispatch("renewals");
      this.loading = false;
    },
    openFile(data) {
      console.log(data);
    },
    viewAddAction(data) {
      this.showAddAction = true;
      this.selectedRenewal = data;
    },
    closeContactClient(value) {
      this.showAddAction = false;
      this.selectedRenewal = {};
      if (value) {
        this.getRenewals();
      }
    },
    viewHistory() {
      this.showHistory = true;
    },
    closeHistory() {
      this.showHistory = false;
    },
  },
};
</script>
